import Vue from 'vue'
import storage from 'store'
import Config from '@/config/config.js'
import store from '@/store'
const apis = require.context('@/config/api', true, /^((?!index).)*.js$/);

export default function created() {
	Vue.prototype.$serviceLink = Config.serviceLink
	Vue.prototype.$appId = Config.appId
	Vue.prototype.$API = {}
	apis.keys().forEach(item => {
	  for (var key in apis(item)) {
	    // 判断是否有重复的api接口名
	    if (Vue.prototype.$API[key]) {
	      Promise.reject(`相同接口名：${key}`)
	    }
	    Vue.prototype.$API[key] = apis(item)[key]
	  }
	})
	let search = window.location.href;
	let agentKey = getQuery('agentKey', search);
	store.commit('setAgentKey', agentKey || storage.get('agentKey') || null);
	store.commit('setToken', storage.get('token') || null);
	store.commit('setUserInfo', storage.get('userInfo') || null);
}
function getQuery(key,search) {  // 搜索路由参数
	if (search.indexOf('?') === -1 || search.indexOf(key) === -1) return null
	else {
		let str = search.split(key+'=')[1]
		if(str.indexOf('&') === -1) return str
		else return str.split('&')[0]
	}
}