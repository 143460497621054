import request from "./index.js"

export function login (data = {}) {  // 授权登录
  return request({
    url: '/v3/ysf/grantAuth',
    method: 'post',
    data: data
  })
}

export function addAccessRecord (data = {}) {  // 添加地区来源
  return request({
    url: '/v3/ysf/addAccessRecord',
    method: 'post',
    data: data
  })
}

export function onQueryCBJY (data = {}) {  // 人保医疗验证
  return request({
    url: '/v3/orderinsure/insured/insuranceCheck',
    method: 'post',
    data: data
  })
}

export function onSubmitBills (data = {}) {  // 提交保单
  return request({
    url: '/v3/youbaolian-product-insure/order/add',
    method: 'post',
    data: data
  })
}

export function queryUnPayOrder (data = {}) {  // 订单查询
  return request({
    url: '/v3/youbaolian-product-insure/order/queryUnPayOrder',
    method: 'post',
    data: data
  })
}

export function cancelPay (data = {}) {  // 用户取消支付（支付失败）
  return request({
    url: '/v3/youbaolian-product-insure/orderPayRecord/cancelPay',
    method: 'post',
    data: data
  })
}

export function getPayTN (data = {}) {  // 获取支付TN号
  return request({
    url: '/v3/youbaolian-product-insure/order/applyPay',
    method: 'post',
    data: data
  })
}

export function orderList (data = {}) {  // 订单列表
  return request({
    url: '/v3/youbaolian-product-insure/tocOrder/orderList',
    method: 'post',
    data: data
  })
}

export function addNewOrder (data = {}) {  // 重新报价
  return request({
    url: '/v3/orderinsure/order/addNewOrder',
    method: 'post',
    data: data
  })
}

export function queryJumpLink (data = {}) {  // 落地页获取支付通道
  return request({
    url: '/v3/youbaolian-product-insure/insureUrlData/queryJumpLink',
    method: 'post',
    data: data
  })
}

export function uniPayOrders (data = {}) {  // 获取优惠通道优惠统计数据
  return request({
    url: '/v3/youbaolian-product-insure/statistics/recruiting/uniPayOrders',
    method: 'post',
    data: data
  })
}

export function relationCustomers (data = {}) {  // 投保人往年被保人参保记录
  return request({
    url: '/v3/youbaolian-product-insure/customer/relationCustomers',
    method: 'post',
    data: data
  })
}

export function orderDetails (data = {}) {  // 订单详情
  return request({
    url: '/v3/youbaolian-product-insure/tocOrder/orderDetails',
    method: 'post',
    data: data
  })
}

export function myPolicys (data = {}) {  // 保单列表
  return request({
    url: '/v3/youbaolian-product-insure/tocOrder/myPolicys',
    method: 'post',
    data: data
  })
}

export function policyDetails (data = {}) {  // 保单详情
  return request({
    url: '/v3/youbaolian-product-insure/tocOrder/policyDetails',
    method: 'post',
    data: data
  })
}

export function cancelOrder (data = {}) {  // 取消订单
  return request({
    url: '/v3/youbaolian-product-insure/order/cancelOrder',
    method: 'post',
    data: data
  })
}
