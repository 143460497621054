<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import storage from 'store';
import { mapGetters } from "vuex";
export default {
  name: 'App',
	computed: {
		...mapGetters(['getAgentKey']),
	},
  mounted() {
		// console.log('env:', process.env.NODE_ENV, this.$serviceLink, this.$appId)
		let that=this
		let search = window.location.href;
		let hash = window.location.hash.split('?')[0];
		let fast = this.getQuery('fast', search);
		if (!+fast && hash === '#/') this.$router.replace('/landing')
		
		/**
		 * 线下二维码场景，携带场景地区参数
		 * */
		// let href=window.location.href,sceneId='';
		// if(href.indexOf('?') != -1 && href.indexOf('sceneId=') != -1) {
		// 	sceneId = href.split('sceneId=')[1]
		// 	if(sceneId.indexOf('#/') != -1) sceneId = sceneId.split('#/')[0]
		// 	if(sceneId.indexOf('&') !== -1) sceneId = sceneId.split('&')[0]
		// 	storage.set('sceneId',sceneId || null)
		// 	upsdk.pluginReady (function(){
		// 		upsdk.appletAuth({
		// 			success: function (data) {
		// 				let code = data.code
		// 				that.$API.login({code: code}).then(res => {
		// 					let params = {
		// 						sceneId: sceneId,
		// 						openId: res.data.openId || null
		// 					}
		// 					that.$API.addAccessRecord(params)
		// 				})
		// 			}
		// 		})
		// 	});
		// }
  },
	methods: {
		getQuery(key,search) {  // 搜索路由参数
			if (search.indexOf('?') === -1 || search.indexOf(key) === -1) return null
			else {
				let str = search.split(key+'=')[1]
				if(str.indexOf('&') === -1) return str
				else return str.split('&')[0]
			}
		}
	}
}
</script>

<style lang="less">
#app {
  /* font-family: 'Avenir', Helvetica, Arial, sans-serif; */
	font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
}
div{
	box-sizing: border-box;
}
img{
	width: 100%;
	height: 100%;
}
.marginT24{
	margin-top: 24px;
}
.marginT36{
	margin-top: 36px;
}
.marginT40{
	margin-top: 40px;
}
.marginT44{
	margin-top: 44px;
}
.textAlignC{
	text-align: center;
}
.textAlignL{
	text-align: left;
}
.textAlignR{
	text-align: right;
}
.fontBold{
	font-weight: bold;
}
.font20{
	font-size: 24px;
	// line-height: 24px;
	transform: scale(0.9);
	transform-origin: left center;
}
.font24{
	font-size: 24px;
	line-height: 26px;
}
.font26{
	font-size: 26px;
	line-height: 38px;
}
.font28{
	font-size: 28px;
	line-height: 28px;
}
.font30{
	font-size: 30px;
	line-height: 34px;
}
.font32{
	font-size: 32px;
	line-height: 36px;
}
.font36{
	font-size: 36px;
	line-height: 40px;
}
.font40{
	font-size: 40px;
	line-height: 44px;
}
.font44{
	font-size: 44px;
	line-height: 48px;
}
.fontColF{
	color: #FFFFFF;
}
.fontCol0{
	// color: #000000;
	color: rgba(0,0,0,0.5);
}
.fontCol3{
	color: #333333;
}
.fontCol6{
	color: #666666;
}
.fontCol9{
	color: #999999;
}
.fontColMain{
	color: #FD3147;
}
.fontRed{
	color: #D41D11;
}
.fontColMinor{
	color: #FA6400;
}
.fontColYel{
	color: #FF9033;
}
.fontColDef{
	color: #40577E;
}
.van-notify--warning{
	background-color: #ff9800 !important;
}
.fontSingleLine{
	overflow:hidden;
	white-space:nowrap;
	text-overflow:ellipsis;
}
</style>
