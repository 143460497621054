import axios from 'axios';
import storage from 'store';
// import store from '@/store';
import { Toast } from 'vant';
// import {Config} from '@/config/config.js'

let baseUrl = '/insapi'
// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: baseUrl,
  timeout: 30000,  // 请求超时时间
})

// 异常拦截处理器
const errorRequest = (error) => {
  return Promise.reject(error)
}
const errorResponse = (error) => {
	Toast(error.response.data.Message || error.response.data.message || error.response.data.msg);
  return Promise.reject(error)
}

const times = new Date().getTime();
// request interceptor
request.interceptors.request.use(config => {
	// console.log('api.config: ',config)
  const token = storage.get('token')
  if (token) {
    config.headers['token'] = token
  }
  return config
}, errorRequest)

// response interceptor
request.interceptors.response.use((response) => {
	if(response.data.ResultCode == 0 || response.data.resultCode == 0 || response.data.code == 200) return response.data
	else {
		Toast(response.data.Message || response.data.message || response.data.msg);
		return Promise.reject(response.data);
	}
}, errorResponse)


export default request
