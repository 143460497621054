import dayjs from 'dayjs'
// 导相对时间插件
import relativeTime from 'dayjs/plugin/relativeTime'
import isoWeek from 'dayjs/plugin/isoWeek'
// 导国际化插件i18n
import 'dayjs/locale/zh-cn'

dayjs.extend(relativeTime)
dayjs.extend(isoWeek)
export const day = {
  now: (value) => {
    return (value && dayjs(value)) || dayjs()
  },
  nowF: (format = 'YYYY-MM-DD') => {
    return dayjs().format(format)
  },
  format: (value, format = 'YYYY-MM-DD') => {
    return (value && dayjs(value).format(format)) || value
  },
  dayFormat: (value, format = 'YYYY-MM-DD') => {
    if (!value) return ''
    if (value.indexOf('-') !== -1) {
      value = value.replace(/\/Date\((-\d+)\)\//g, '$1')
    } else {
      value = value.replace(/\/Date\((\d+)\)\//g, '$1')
    }
    return dayjs(new Date(+value)).format(format)
  },
  fromNow: (value) => {
    if (!value) return ''
    if (value.indexOf('-') !== -1) {
      value = value.replace(/\/Date\((-\d+)\)\//g, '$1')
    } else {
      value = value.replace(/\/Date\((\d+)\)\//g, '$1')
    }
    return dayjs(new Date(+value)).locale('zh-cn').fromNow()
  },
  /**
 * 获取 距离当前 还有多少天
 */
  getDateMinus (sDate) {
    const sdate = new Date(sDate.replace(/-/g, '/'))
    const now = new Date()
    const days = sdate.getTime() - now.getTime()
    const day = parseInt(days / (1000 * 60 * 60 * 24))
    return day
  },
  getLastMonthdy (monthNum) {
    let lastmonth = new Date(new Date().setMonth((new Date().getMonth() - monthNum)))
    lastmonth.setTime(lastmonth.getTime() - 24 * 60 * 60 * 1000)
    var datastr = lastmonth.getFullYear() + '-' + (lastmonth.getMonth() + 1) + '-' + lastmonth.getDate()
    return datastr
  },
  getDateStr (dayCount) {
    var dd = new Date()
    dd.setDate(dd.getDate() + dayCount)
    var y = dd.getFullYear()
    var m = dd.getMonth() + 1
    if (m < 10) {
      m = '0' + m
    }
    var d = dd.getDate()
    if (d < 10) {
      d = '0' + d
    }
    return y + '-' + m + '-' + d
  }
}
