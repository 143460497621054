export const policy = {
  identityType: [
    {
      label: '身份证',
      value: '11'
    }, {
      label: '港澳居民往来内地通行证',
      value: '7'
    }, {
      label: '外国人护照',
      value: '2'
    }, {
      label: '港澳台居民居住证',
      value: '18'
    }, {
      label: '外国人永久居留证',
      value: '19'
    }
  ]
}

export default {
  policy
}
