import Vue from 'vue'
import Vuex from 'vuex'
import storage from 'store'
import Config from '@/config/config.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
		agentKey: storage.get('agentKey') || '', // 渠道信息
    token: storage.get('token') || '',
    userInfo: {
      openId: '',
      mobile: '',
			realName: '',
			certTp: '',
			certId: '',
    },
		openNotice: false,
		endDate: Config.endDate
  },
  mutations: {
		setAgentKey (state, val) {
      state.agentKey = val
      storage.set('agentKey', val)
    },
    setToken (state, val) {
      state.token = val
      storage.set('token', val)
    },
    setUserInfo (state, val) {
			if (val && val.openId && val.mobile && val.realName && val.certTp && val.certId) {
				let { openId, mobile, realName, certTp, certId } = val
				state.userInfo = { openId, mobile, realName, certTp, certId }
				storage.set('userInfo', { openId, mobile, realName, certTp, certId })
			} else {
				state.userInfo = null
				storage.set('userInfo', null)
			}
    },
		setNotice (state, val) {
      state.openNotice = val
    },
  },
  getters: {
		getAgentKey: state => state.agentKey,
    getToken: state => state.token,
    getUserInfo: state => state.userInfo,
		getNotice: state => state.openNotice,
		endDate: state => state.endDate
  },
  actions: {
  },
  modules: {
  }
})