import * as enumData from '@/data'

const factory = (value, source) => {
  if (value === undefined) {
    return value
  }
  let obj = source.find(c => `${c.value === undefined ? c.Value : c.value}`.trim() === `${value}`.trim())
  return (obj && (obj.label || obj.Label)) || value
}

let methods = {}
const getData = (value) => {
  Object.keys(value).forEach(key => {
    let type = value[key] instanceof Array

    if (type) {
      methods[key] = (val) => {
        return factory(val, value[key])
      }
    } else {
      getData(value[key])
    }
  })
}

getData(enumData)
console.log('🚀 ~ file: data.js:48 ~ enumData:', enumData.default.finance)
export const data = { ...methods }
