import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import created from "@/utils/created"
import * as filters from '@/filters'
// import "@/utils/rem.js"
import VueCupUi from 'vue-cup-ui';
import 'vue-cup-ui/lib/vue-cup-ui.css';
Vue.use(VueCupUi);
import vantComponents from "@/utils/vantComponents";
Vue.use(vantComponents);
import 'vant/lib/index.css';
// px2rem 自适应
import 'lib-flexible'

Vue.use(Vant)
Vue.config.productionTip = false

const setFilter = (value, type = 1) => {
  Object.keys(value).forEach(key => {
    if (type === 1) {
      if (typeof value[key] === 'function') {
        Vue.filter(key, value[key])
      } else {
        setFilter(value[key], type)
      }
    } else {
      Vue.directive(key, value[key])
    }
  })
}
setFilter(filters)

new Vue({
  router,
  store,
  created,
  render: h => h(App)
}).$mount('#app')
