import { day } from '@/utils'
import { data } from '@/utils/data'
import { supplier, supplierContact } from '@/utils/dictionary.js'

/**
 * 过滤器
 * 全局过滤器定义
 */
export function wordFormat (value) { // xxxx年xx月xx日
  let data = day.format(value, 'YYYY-MM-DD')
  let [y, m, d] = data.split('-')
  return `${y}年${m}月${d}日`
}

export function dayFormat (value, format = 'YYYY-MM-DD') {
  return day.dayFormat(value, format)
}

export function Format (value, format = 'YYYY-MM-DD') {
  if (value) {
    return day.format(value, format)
  } else {
    return value
  }
}

export function yearFormat (value, format = 'YYYY') {
  return day.format(value, format)
}

export function fromNow (value) {
  return day.fromNow(value)
}

export function supplierName (value) {
  let ret = ''
  supplier.map(c => {
    if (+c.value === +value) {
      ret = c.text
    }
  })
  return ret
}

export function supplierContactMobile (value) {
  let ret = ''
  supplierContact.map(c => {
    if (+c.value === +value) {
      ret = c.text
    }
  })
  return ret
}

const filters = {}
Object.keys(data).forEach(key => {
  filters[key] = (value) => {
    return data[key](value)
  }
})

export const dataFilter = { ...filters }
