import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: function () {
      return import('../views/home/home.vue')
    }
  },
  {
    path: '/landing',
    name: 'Landing',
    component: function () {
      return import('../views/home/landing.vue')
    }
  },
  {
    path: '/order',
    name: 'Order',
    component: function () {
      return import('../views/order/index.vue')
    }
  },
  {
    path: '/unpaid',
    name: 'Unpaid',
    component: function () {
      return import('../views/pay/unpaid.vue')
    }
  },
  {
    path: '/paid',
    name: 'Paid',
    component: function () {
      return import('../views/pay/paid.vue')
    }
  },
  {
    path: '/pdf',
    name: 'pdf',
    component: function () {
      return import('../views/order/pdf.vue')
    }
  },
  {
    path: '/personal',
    name: 'personal',
    component: function () {
      return import('../views/personal/index.vue')
    }
  },
  {
    path: '/personal/info',
    name: 'personal/info',
    component: function () {
      return import('../views/personal/info.vue')
    }
  },
  {
    path: '/order/details',
    name: 'order/details',
    component: function () {
      return import('../views/order/details.vue')
    }
  },
  {
    path: '/login',
    name: 'login',
    component: function () {
      return import('../views/login.vue')
    }
  },
  {
    path: '/policy',
    name: 'policy',
    component: function () {
      return import('../views/policy/index.vue')
    }
  },
  {
    path: '/policy/details',
    name: 'policy/details',
    component: function () {
      return import('../views/policy/details.vue')
    }
  }
]

const router = new Router({
  mode: 'hash',
  base: '/',
  routes
})

export default router