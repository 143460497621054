export const order = {
  orderStatus: [{
    value: 2, label: '待支付'
  }, {
    value: 3, label: '已支付'
  }, {
    value: 4, label: '已关闭'
  }, {
    value: 5, label: '已取消'
  }],
  orderPayStatus: [{
    value: 0, label: '待支付'
  }, {
    value: 1, label: '支付结果查询中'
  }, {
    value: 2, label: '支付成功'
  }, {
    value: 3, label: '支付失败'
  }, {
    value: 8, label: '待退款'
  }, {
    value: 9, label: '订单转入退货流程'
  }, {
    value: 4, label: '退款结果查询中'
  }, {
    value: 5, label: '退款成功'
  }, {
    value: 6, label: '退款失败'
  }, {
    value: 7, label: '取消支付'
  }],
  orderLowerStatus: [
    {
      label: '待支付',
      value: 1
    },
    {
      label: '已取消',
      value: 102
    },
    {
      label: '核保成功',
      value: 213
    },
    {
      label: '核保失败',
      value: 202
    },
    {
      label: '支付成功',
      value: 301
    },
    {
      label: '支付失败',
      value: 302
    },
    {
      label: '退款成功',
      value: 305
    },
    {
      label: '退款失败',
      value: 306
    },
    {
      label: '重新尝试投保',
      value: 400
    },
    {
      label: '已承保',
      value: 401
    },
    {
      label: '自动承保',
      value: 402
    },
    {
      label: '已退保',
      value: 601
    },
    {
      label: '已撤单',
      value: 203
    },
    {
      label: '已拆单',
      value: 999
    },
    {
      label: '已失效',
      value: 0
    }
  ]
}

export default {
  order
}
